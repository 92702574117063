// import config from '@/config';
import axios from "@/libs/api.request";
import storage from "@/libs/storage";

const Api = {};

/**
 * @description 取消家访
 */
Api.cancel = (opts) =>
  axios.request({
    url: "/api/app/home/caseHandle/cancel",
    data: opts,
  });

/**
 * @description 家访详情
 */
Api.findDetailByHomeNo = (homeNo) =>
  axios.request({
    url: `/api/app/home/caseHandle/findDetailByHomeNo/${homeNo}`,
    method: "get",
  });

/**
 * @description 调度中心家访记录列表
 */
Api.findPageISO = (opts) =>
  axios.request({
    url: "/api/app/home/caseHandle/findPageISO",
    data: opts,
  });

/**
 * @description 确认接单
 */
Api.receive = (opts) =>
  axios.request({
    url: "/api/app/home/caseHandle/receive",
    data: opts,
  });

/**
 * @description 派单
 */
Api.assignment = (opts) =>
  axios.request({
    url: `/api/app/home/caseHandle/case/assignment/${opts.homeNo}`,
    data: opts.operatorId,
  });

/**
 * @description 改单
 */
Api.change = (opts) =>
  axios.request({
    url: `/api/app/home/caseHandle/case/change/${opts.homeNo}`,
    data: opts.operatorId,
  });

/**
 * @description 接单
 */
Api.accept = (opts) =>
  axios.request({
    url: `/api/app/home/caseHandle/case/accept/${opts.homeNo}/${opts.dateStr}`,
    data: opts,
  });

/**
 * @description 退单
 */
Api.reject = (opts) =>
  axios.request({
    url: `/api/app/home/caseHandle/case/reject/${opts.homeNo}/${opts.reason}`,
    data: opts,
  });

/**
 * @description 改期
 */
Api.changeTime = (opts) =>
  axios.request({
    url: `/api/app/home/caseHandle/case/home/time/${opts.homeNo}/${opts.dateStr}`,
    data: opts,
  });

/**
 * @description 中止家访
 */
Api.suspensionOfHomeVisits = (opts) =>
  axios.request({
    url: `/api/v4/ibp/outsource/commonDispatchedSuspend/create`,
    data: opts,
  });

/**
 * @description 查询补充信息
 */
Api.reportAdditionList = (assignNo) =>
  axios.request({
    url: "/api/app/home/caseHandle/reportAdditionList",
    data: {},
    params: {
      assignNo,
    },
  });

/**
 * @description 查询结案申请内容
 */
Api.getFinishApply = (assignNo) =>
  axios.request({
    url: `/api/app/home/caseHandle/getFinishApply`,
    method: "get",
    params: {
      assignNo,
    },
  });

/**
 * @description 新增结案申请
 */
Api.addFinishApply = (data) =>
  axios.request({
    url: "/api/app/home/caseHandle/addFinishApply",
    data: data,
    params: {},
  });

/**
 * @description 提交证据资料
 */
Api.evidenceAttach = (data) =>
  axios.request({
    url: "/api/app/home/caseHandle/case/evidence/attach",
    data: data,
    params: {},
  });

/**
 * @description 保存结案申请2
 */
Api.finishApply2 = (data) =>
  axios.request({
    url: "/api/app/home/caseHandle/finishApply2",
    data: data,
    params: {},
  });

/**
 * @description 修改结案申请
 */
Api.updateFinishApply = (data) =>
  axios.request({
    url: "/api/app/home/caseHandle/updateFinishApply",
    data: data,
    params: {},
  });

/**
 * @description 家访报告核实信息详情
 */
Api.reportVerifyInfo = (assignNo) =>
  axios.request({
    url: `/api/app/home/caseHandle/reportVerifyInfo/${assignNo}`,
    method: "get",
  });

/**
 * @description 保存补充信息
 */
Api.saveHomeReportAddition = (opts) =>
  axios.request({
    url: "/api/app/home/caseHandle/saveHomeReportAddition",
    data: opts,
  });

/**
 * @description 保存家访反馈信息详情
 */
Api.saveReportFeedbackInfo = (opts) =>
  axios.request({
    url: "/api/app/home/caseHandle/saveReportFeedbackInfo",
    data: opts,
  });

/**
 * @description 保存家访报告核实信息
 */
Api.saveReportVerifyInfo = (opts) =>
  axios.request({
    url: "/api/app/home/caseHandle/saveReportVerifyInfo",
    data: opts,
  });

/**
 * @description 保存家访报告核实信息
 */
Api.homeResultConfigList = () =>
  axios.request({
    url: "/api/app/home/caseHandle/homeResultConfigList",
    data: {},
  });

/**
 * @description 区域列表
 */
Api.regions = async () => {
  const KEY = "REGIONS";
  var res = storage.getLocal(KEY);
  if (res != null) {
    return res;
  }
  res = await axios.request({
    url: `/api/app/home/caseHandle/regions`,
    method: "get",
  });
  storage.setLocal(KEY, res);
  return res;
};

// 查询首页统计
Api.getOrgIndexCount = async () => {
  const res = await axios.request({
    url: `/api/app/home/caseHandle/getOrgIndexCount`,
    method: "get",
  });
  return res;
};

// 新增延期申请
Api.addDelayApply = (opts) =>
  axios.request({
    url: "/api/app/home/caseHandle/addDelayApply",
    data: opts,
    params: {},
  });

// 查询延期申请记录
Api.findDelayApplyList = (opts) =>
  axios.request({
    url: "/api/app/home/caseHandle/findDelayApplyList",
    data: opts,
  });

// 新增案件反馈记录
Api.addFeedback = (opts) =>
  axios.request({
    url: "/api/app/home/caseHandle/addFeedback",
    data: opts,
    params: {},
  });

// 查询案件反馈记录
Api.findFeedbackList = (opts) =>
  axios.request({
    url: "/api/app/home/caseHandle/findFeedbackList",
    data: opts,
    params: {},
  });

// 家访反馈记录分页查询
Api.feedbackPage = (opts) =>
  axios.request({
    url: "/api/app/home/caseHandle/case/home/feedback/page",
    data: opts,
    params: {},
  });

/**
 * 结清试算
 * @param {Object} data
 * @returns
 */
Api.repayCalculate = ({ platform, ...data }) =>
  axios.request({
    url: `/api/v4/ibp/finance/capitalTrial/repayCalculate/${platform}`,
    data,
  });

/**
 * 结清试算记录
 * @param {Object} data
 * @returns
 */
Api.repayFindPage = ({ pageNo, pageSize, ...data }) =>
  axios.request({
    url: `/api/v4/ibp/finance/capitalTrial/findPage`,
    method: "POST",
    data,
    params: {
      pageNo,
      pageSize,
    },
  });

/**
 * 通过 bizNo 查询借据详情
 * @param {*} projectCode
 * @returns
 */
Api.findByBizNo = (projectCode) =>
  axios.request({
    url: `/api/v4/ibp/finance/iou/findByBizNo/${projectCode}`,
    method: "GET",
  });

export default Api;
