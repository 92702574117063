<template>
  <div class="case">
    <div class="content">
      <div class="attrs">
        <template v-if="type == 'family'">
          <div>是否见到本人：{{ item.seenInPerson | enumMessage(defaultEnum) | dash }}</div>
          <div>是否长期居住：{{ item.longTermResident | enumMessage(defaultEnum) | dash }}</div>
          <div>家庭人数：{{ item.familySize | dash }}</div>
          <div>家庭结构：{{ item.familyStructure | dash }}</div>
          <div>收入来源：{{ item.incomeSource | dash }}</div>
          <div>家庭月收入：{{ item.monthlyExpenses | dash }}</div>
          <div>车辆状态：{{ item.vehicleStatus | dash }}</div>
          <div>停放位置：{{ item.parkingLocation | dash }}</div>
        </template>

        <template v-if="type == 'visit'">
          <div>上门地址({{ item.addressType | enumMessage(getAddrTypeEnum) | dash }})：{{ item.visitAddress | dash }}</div>
          <div>家访对象：{{ item.visitObject | dash }}</div>
          <div>外访日期：{{ item.visitDate | dash }}</div>
          <div>是否有效：{{ item.addressVal | dash }}</div>
          <div>外访情况：{{ item.visitCondition | dash }}</div>
        </template>

        <template v-if="type == 'repayment'">
          <div>回款金额：{{ item.amount | dash }}</div>
          <div>回款类型：{{ item.repaymentType | enumMessage(getRepaymentTypeEnum) | dash }}</div>
          <div>回款人：{{ item.repaymentUserName | dash }}</div>
          <div>回款时间：{{ item.repaymentDate | dash }}</div>
          <div class="line"></div>
          <div class="pictures">
            <Uploader v-if="item.attachListDetail && item.attachListDetail.length" :value="item.attachListDetail" :readonly="true" />
            <div v-else class="empty-pic">无附件</div>
          </div>
        </template>

        <template v-if="type == 'feedback'">
          <div>客户情况：{{ item.customCondition | enumMessage(getHomeCustomerConditionEnum) | dash }}</div>
          <div>跟进结果：{{ item.followStatus | enumMessage(getHomeFollowStatusEnum) | dash }}</div>
          <div>跟进时间：{{ item.followDate | dash }}</div>
          <div>跟进详情：{{ item.followDetails | dash }}</div>
          <div class="line"></div>
          <div class="pictures">
            <Uploader v-if="item.attachList && item.attachList.length" :value="item.attachList" :readonly="true" />
            <div v-else class="empty-pic">无附件</div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
const DEFAULT_ENUM = [{
  value: '1',
  displayName: '是'
}, {
  value: '0',
  displayName: '否'
}]

export default {
  props: {
    item: {
      type: Object,
      default: () => { },
    },
    type: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      defaultEnum: DEFAULT_ENUM,
    };
  },
  methods: {

  },
  mounted() {
    this.fetchEnums("getHomeCustomerConditionEnum", "getHomeFollowStatusEnum", "getAddrTypeEnum", "getRepaymentTypeEnum");
  },
};
</script>
<style lang="less" scoped>
.case {
  background: #ffffff;
  border-radius: 4px;
  position: relative;
  user-select: text;

  .content {
    position: relative;
    display: flex;

    .attrs {
      flex: 1;
      padding: 16px;

      div {
        color: #787b84;
        font-size: 12px;
        line-height: 1.8em;
      }
    }
  }

  .line {
    height: 1px;
    background: #f1f2f7;
  }

  .pictures {
    padding: 5px 5px;
  }
  .empty-pic {
    height: 24px;
    padding: 0px 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: gray;
  }
}
</style>
