<template>
  <div id="app" v-watermark>
    <router-view/>
  </div>
</template>

<script>
  export default {
    name: 'app'
  }
</script>
<style  scoped>
</style>
