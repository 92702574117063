<template>
  <van-popup v-model="showPopup" position="bottom" @close="closePopup">
    <div class="distribute-popup">
      <div class="distribute-popup-title">{{ actionType }}</div>
      <div class="line"></div>
      <div class="distribute-popup-content">
        <van-form>
          <van-field
            v-if="['退单'].includes(actionType)"
            required
            label="退单原因："
            name="valueReason"
            v-model="form.valueReason"
            placeholder="请输入退单原因"
          />
          <van-field
            v-if="['改派', '派单'].includes(actionType)"
            readonly
            required
            clickable
            label="家访人员："
            :value="form.valuePerson"
            placeholder="请选择家访人员"
            @click="showPicker = true"
          />
          <van-field
            v-if="['接单', '改期'].includes(actionType)"
            readonly
            required
            clickable
            :label="'家访时间：'"
            :value="form.valueDate"
            placeholder="请选择日期"
            @click="showPicker = true"
          />

          <template v-if="['中止'].includes(actionType)">
            <van-field
              name="addressType"
              required
              label-width="78px"
              label="中止类型："
              class="form-no-boder"
            >
              <template #input>
                <van-checkbox-group
                  v-model="form.suspendType"
                  direction="horizontal"
                >
                  <van-checkbox
                    v-for="(item, index) in dispatchedSuspendTypeEnum"
                    :name="item.value"
                    :key="index"
                    >{{ item.displayName }}</van-checkbox
                  >
                  <!-- <van-checkbox name="b">收车</van-checkbox>
                  <van-checkbox name="c">诉讼</van-checkbox> -->
                </van-checkbox-group>
              </template>
            </van-field>
            <van-field
              required
              label-width="78px"
              label="原因："
              readonly
              clickable
              :value="form.suspendReason"
              name="addressType"
              placeholder="请选择"
              @click="showPicker = true"
              class="form-no-boder"
            >
            </van-field>
            <van-field
              required
              v-if="form.suspendReason == '其他'"
              label-width="78px"
              label="其他原因："
              type="textarea"
              autosize
              rows="1"
              name="valueReason"
              maxlength="100"
              v-model="form.reason"
              placeholder="请输入"
            />
          </template>

          <template
            v-if="
              ['户籍地址', '居住地址', '房产地址', '单位地址'].includes(
                actionType
              )
            "
          >
            <van-field
              :label="`${actionType}：`"
              v-model="form.address"
              :placeholder="`请输入${actionType}`"
            />
            <van-field name="addressType" label="" class="form-no-boder">
              <template #input>
                <van-radio-group
                  v-model="form.addressType"
                  direction="horizontal"
                >
                  <van-radio name="1">地址有效</van-radio>
                  <van-radio name="2">地址无效</van-radio>
                </van-radio-group>
              </template>
            </van-field>
            <van-field
              required
              :label="`新${actionType}：`"
              v-model="form.newAddress"
              :placeholder="`请输入新${actionType}`"
            >
              <template #button>
                <van-button size="mini" type="info">获取定位</van-button>
              </template>
            </van-field>
            <van-field
              name="attachList"
              label="地址照片："
              class="form-no-boder"
            >
              <template #input>
                <Uploader accept="image/*" v-model="form.attachList" />
              </template>
            </van-field>
          </template>

          <van-popup v-model="showPicker" round position="bottom">
            <van-picker
              v-if="['改派', '派单'].includes(actionType) && !multiple"
              show-toolbar
              :columns="listPersonFilter"
              @cancel="showPicker = false"
              @confirm="onConfirmPerson"
            >
              <template #columns-top>
                <van-field
                  v-model="searchKey"
                  :placeholder="`请输入名称筛选`"
                />
              </template>
            </van-picker>
            <van-picker
              v-if="['中止'].includes(actionType)"
              show-toolbar
              :columns="merchants"
              @cancel="showPicker = false"
              @confirm="onConfirmReason"
            />
            <div v-if="['改派', '派单'].includes(actionType) && multiple">
              <div class="van-picker__toolbar">
                <button
                  type="button"
                  class="van-picker__cancel"
                  @click="closeChildPopup(1)"
                >
                  取消
                </button>
                <button
                  type="button"
                  class="van-picker__confirm"
                  @click="closeChildPopup(2)"
                >
                  确认
                </button>
              </div>
              <van-checkbox-group ref="checkboxGroup" v-model="checkedValue">
                <van-cell-group>
                  <van-cell
                    v-for="(item, index) in listPersonFilter"
                    clickable
                    :key="item.operatorId"
                    :title="item.text"
                    @click="toggle(item, index)"
                  >
                    <template #right-icon>
                      <van-checkbox
                        :name="item.operatorId"
                        ref="checkboxes"
                        shape="square"
                      />
                    </template>
                  </van-cell>
                </van-cell-group>
              </van-checkbox-group>
            </div>
            <van-datetime-picker
              v-if="['接单', '改期'].includes(actionType)"
              v-model="currentDate"
              type="date"
              title="选择年月日"
              :min-date="minDate"
              :max-date="maxDate"
              @cancel="showPicker = false"
              @confirm="onConfirmDate"
            />
          </van-popup>
        </van-form>
      </div>
      <div class="line"></div>
      <div class="distribute-popup-btns">
        <van-button size="small" @click="cancelPopup">取 消</van-button>
        <van-button type="info" size="small" @click="confirmPopup"
          >确 认</van-button
        >
      </div>
    </div>
  </van-popup>
</template>

<script>
import Api from "@/api/user";
import dayjs from "dayjs";
import * as _ from "lodash";
const DEFAULT_FORM = {
  valueReason: "",
  valuePerson: "",
  valuePersonId: "",
  valueDate: "",

  address: "",
  addressType: "",
  newAddress: "",
  attachList: [],
  suspendReason: "",
  reason: "",
  suspendType: [],
};

export default {
  components: {},
  props: {
    value: {
      type: Boolean,
      default: () => false,
    },
    actionType: {
      type: String,
      default: () => "",
    },
    actionItem: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      userBaseInfo: {
        resOperatorDTO: {},
      },
      showPopup: false,
      form: {
        ..._.cloneDeep(DEFAULT_FORM),
      },
      showPicker: false,
      minDate: new Date(),
      maxDate: new Date(2100, 12, 30),
      currentDate: new Date(),
      listPerson: [],
      checkedValue: [],
      selectList: [],
      searchKey: "",
      multiple: true,
      dispatchedSuspendTypeEnum: [],
      merchants: [],
    };
  },
  computed: {
    listPersonFilter() {
      return (this.listPerson || []).filter(
        (e) => e.name.indexOf(this.searchKey) != -1
      );
    },
  },
  watch: {
    value: {
      deep: true,
      handler(value) {
        this.showPopup = value;
        // this.$set(this, "form", DEFAULT_FORM);
        if (value && this.actionType == "中止") {
          this.getDispatchedSuspendTypeEnum();
          this.getLevelOneEnum();
        }
      },
    },
  },
  async mounted() {
    const userBaseInfo = await Api.baseInfo();
    userBaseInfo.success && (this.userBaseInfo = userBaseInfo.data);
    this.userBaseInfo.resOperatorDTO.isAdmin && this.getListPerson();
  },
  methods: {
    async getDispatchedSuspendTypeEnum() {
      let res = await Api.getDispatchedSuspendTypeEnum();
      this.dispatchedSuspendTypeEnum = res.data;
    },
    async getLevelOneEnum() {
      let { data } = await Api.commonConfigDictFindListPost({
        dictTypeEq: "DISPATCHED_SUSPEND_REASON",
      });
      this.merchants = ((data[0] && data[0].childConfig) || []).map((e) => ({
        ...e,
        text: e.dictValue,
      }));
    },
    async getListPerson() {
      let res = await Api.findNormalList();
      this.listPerson = (res.data || []).map((e) => ({ ...e, text: e.name }));
    },
    // 关闭弹出层
    closePopup() {
      this.$set(this, "form", { ...DEFAULT_FORM });
      this.$emit("input", false);
    },
    // 选择家访人员确认
    onConfirmPerson(value) {
      this.$set(this.form, "valuePerson", value.text);
      this.$set(this.form, "valuePersonId", value.operatorId);
      this.showPicker = false;
    },
    // 选择原因
    onConfirmReason(value) {
      this.$set(this.form, "suspendReason", value.text);
      this.$set(this.form, "choiseReasonId", value.operatorId);
      this.showPicker = false;
    },
    // 选择请选择日期确认
    onConfirmDate(value) {
      this.$set(this.form, "valueDate", dayjs(value).format("YYYY-MM-DD"));
      this.showPicker = false;
    },
    // 弹出层取消
    cancelPopup() {
      this.showPopup = false;
    },
    // 弹出层确认
    confirmPopup() {
      this.$emit("confirmPopup", this.form);
    },
    toggle(item) {
      var idx = this.selectList.findIndex(
        (ele) => ele.operatorId === item.operatorId
      );
      if (idx > -1) {
        this.selectList.splice(idx, 1);
      } else {
        this.selectList.push(item);
      }
    },
    closeChildPopup(type) {
      if (type == 1) {
        this.selectList = [];
        this.checkedValue = [];
        this.showPicker = false;
      } else {
        this.endSure();
      }
    },
    endSure() {
      this.$set(
        this.form,
        "valuePerson",
        this.selectList.map((e) => e.text).join(",")
      );
      this.$set(this.form, "valuePersonId", this.checkedValue);
      this.selectList = [];
      this.checkedValue = [];
      this.showPicker = false;
    },
  },
};
</script>

<style lang="less" scoped>
.distribute-popup {
  .distribute-popup-title {
    height: 50px;
    line-height: 50px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
  }

  .distribute-popup-content {
    padding: 24px 12px;

    /deep/ .van-cell {
      &.van-cell--required::before {
        padding: 4px 0px;
      }

      .van-field__label {
        margin-right: 0;
        width: unset;
        padding: 4px 0px;
      }

      .van-field__value {
        border: 1px solid #d3d6dc;
        padding: 4px 8px;
      }

      &.form-no-boder {
        .van-field__value {
          border: none;
        }
      }

      .van-uploader__upload {
        background: #f2f2f2;
      }
    }
  }

  .distribute-popup-btns {
    padding: 12px 24px;
    display: flex;

    .van-button {
      flex: 1;
    }

    .van-button + .van-button {
      margin-left: 24px;
    }
  }

  .line {
    height: 1px;
    background: #d3d6dc;
  }
}
</style>
